/* eslint no-console:0 */

import "@hotwired/turbo-rails"

require("@rails/activestorage").start()
require("local-time").start()

//Lightgallery
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'


import "./channels"
import "./controllers"
import "./src/**/*"

